.container {
  margin-left: -100px;
  display: flex;
  justify-content: center; /* Horizontal zentrieren */
  align-items: flex-start; /* Oben ausrichten */
  height: 100vh; /* 100% der Bildschirmhöhe */
}

/* Hier wird das innere Div gestylt */
.zentriertes-div {
  width: 800px; /* Breite des Divs anpassen */
  height: 200px; /* Höhe des Divs anpassen */
  text-align: center; /* Text horizontal zentrieren */
  padding: 20px; /* Innenabstand für den Inhalt */
  text-align: left;
 
}

.überschrift {
  font-size: 30px;
  font-family: "Verdana";
}

.centered-list {
  text-align: left; /* Links ausrichten, um die Punkte zu erhalten */
  padding-left: 50px; /* Einrückung für die Punkte */
}

.impressum h1 {
  font-size: 30px;
  margin-bottom: 10px;
  margin: 4px 10px;
}

.impressum address p {
  font-size: 16px;
  margin: 4px 10px;
}
.bold {
  margin-left: -10px;
  font-weight: 600;
  padding: 10px;
}