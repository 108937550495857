.container {
    display: flex;
    justify-content: center; /* Horizontal zentrieren */
    align-items: flex-start; /* Oben ausrichten */
    height: 100vh; /* 100% der Bildschirmhöhe */
    overflow-y: auto; /* Vertikale Scrollleiste hinzufügen, wenn der Inhalt über den sichtbaren Bereich hinausgeht */
}

/* Hier wird das innere Div gestylt */
.zentriertes-div {
    width: 800px; /* Breite des Divs anpassen */
    text-align: left; /* Text links ausrichten */
    padding: 20px; /* Innenabstand für den Inhalt */
}

.überschrift {
    font-size: 30px;
    font-family: "Verdana";
}

.centered-list {
    text-align: left; /* Links ausrichten, um die Punkte zu erhalten */
    padding-left: 50px; /* Einrückung für die Punkte */
}
