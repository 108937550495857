body {
    overflow: hidden;
}

.grid-container {
    margin-bottom: auto;
    margin-left: 230px;
    margin-right: auto;
    margin-top: -30px;
    display: grid;
    flex: 1;
    grid-template-columns: repeat(8, 180px);
    padding: 8px;
    grid-column-gap: 20px;
    position: relative;
    justify-content: center;
}
@keyframes flyInTop {
    from {
        transform: translateY(-100%);
        opacity: 0;
    }
    to {
        transform: translateY(0);
        opacity: 1;
    }
}

@keyframes flyInBottom {
    from {
        transform: translateY(100%);
        opacity: 0;
    }
    to {
        transform: translateY(0);
        opacity: 1;
    }
}

.grid-item.top {
    animation: flyInTop 1s ease-in-out forwards;
    position: relative;
}

.grid-item.bottom {
    animation: flyInBottom 1s ease-in-out forwards;
    position: relative;
}
